import { css } from 'styled-components';

import { ThemeName } from 'models/Theme';
import { themes } from 'theme';

export function generateCSSVarsFromTheme(name: ThemeName) {
	const colors = themes[name].colors;

	return css`
		--color-primary: ${colors.primary} !important;
		--color-secondary: ${colors.secondary} !important;

		--color-surface: ${colors.surface} !important;

		--color-title: ${colors.title} !important;
		--color-text: ${colors.text} !important;

		--color-background: ${colors.background} !important;
		--color-line: ${colors.line} !important;
	`;
}
