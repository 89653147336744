import styled from 'styled-components';

import { generateCSSVarsFromTheme } from 'helpers/generateCSSVarsFromTheme';

export const HomeContainer = styled.div`
	${generateCSSVarsFromTheme('dark')}
	background: var(--color-background);
	overflow-y: hidden;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: var(--color-title);
	}

	p {
		color: var(--color-text);
	}

	section#banner {
		position: relative;
		/* padding-bottom: 5rem !important; */

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			/* Background escuro com um efeito de pontos focais ainda mais suaves */
			background: radial-gradient(
					circle at 20% 40%,
					rgba(255, 255, 255, 0.08),
					transparent 50%
				),
				radial-gradient(
					circle at 80% 60%,
					rgba(255, 255, 255, 0.05),
					transparent 60%
				),
				radial-gradient(
					circle at 50% 80%,
					rgba(255, 255, 255, 0.06),
					transparent 55%
				),
				#000000; /* Cor de fundo principal (preto) */
			background-position: center;
			background-size: cover;
		}

		.content {
			position: relative;
			z-index: 100;

			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: #ffffff;

			font-family: 'Public Sans', sans-serif;
			padding: 1rem 1rem;
			line-height: 1.5;
		}
	}

	section#screenshot {
		overflow: hidden;
		padding-top: 0 !important;

		.content {
			display: flex;
			align-items: center;
			justify-content: center;

			.screenshot {
				position: relative;

				width: 90%;
				height: auto;
				/* aspect-ratio: 1440 / 900; */

				/* background: var(--color-surface); */
				border-radius: 0.5rem;

				box-sizing: content-box;

				overflow: hidden;

				img {
					/* display: none; */
					width: 100%;
					height: 100%;
					height: auto-fit;
					object-fit: cover;
					object-position: left;
				}

				.overlay {
					position: absolute;
					top: 0;
					left: 0;

					z-index: 100;

					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 1.5rem;

					width: 100%;
					height: 100%;

					background: rgba(0, 0, 0, 0.5);
					backdrop-filter: blur(5px);
					opacity: 0;

					transition: 500ms;

					p {
						text-align: center;
						font-weight: 500;

						/* color: #000; */
					}
				}

				iframe {
					position: absolute;
					top: 0;
					left: 0;

					z-index: 10;

					width: 100%;
					height: 100%;
					object-fit: contain;
				}

				&:hover {
					.overlay {
						opacity: 1;
					}
				}
			}
		}
	}

	section#customers {
		.content {
		}
	}

	section#testimonials {
		overflow: hidden;
	}

	section#talk-to-a-expert {
		.content {
		}
	}

	section#steps {
		.content {
		}
	}

	section#video {
		.content {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	section#benefits {
		.content {
		}
	}

	section#classification,
	section#conversion {
		.content {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.text {
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				gap: 0.75rem;

				width: 31.25rem;

				h1 {
					font-weight: 400;
					font-size: 2.5rem;

					margin-top: 0.75rem;
				}

				p {
					line-height: 1.5;
				}

				.institutional-button {
					margin-top: 1.5rem;
				}
			}

			.resource {
				position: relative;

				display: flex;
				align-items: center;
				justify-content: center;

				width: 31.25rem;
				height: 31.25rem;

				overflow: visible;

				/* background: #fff; */
			}
		}
	}

	@media (max-width: 811px) {
		section,
		header,
		footer {
			overflow: hidden;
		}

		section {
			.content {
				> div {
					width: 100% !important;

					> .text,
					> .heading {
						width: 100% !important;

						h1 {
							font-size: 2.75rem;
						}
					}

					> .heading {
					}
				}
			}
		}

		section#banner {
			padding-top: 18.60465vw !important;

			.content {
				> div {
					width: 100%;

					.text {
						margin-top: 0;

						h1 {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: flex-start;
							text-align: center;

							font-size: 13.02326vw;
							width: 100%;

							> br {
								display: none;
							}

							&,
							span {
								line-height: 130%;
							}

							span,
							div {
								display: flex;
								align-items: center;

								width: fit-content;
								height: 13.02326vw;
								vertical-align: middle;

								text-align: center;
							}
						}

						p {
							width: 100%;
							padding: 0 !important;

							font-size: 1rem;
						}
					}
				}
			}
		}

		section#benefits {
			.content {
				> div {
					.benefits {
						grid-template-columns: 1fr;
					}
				}
			}
		}
	}
`;

export default HomeContainer;
