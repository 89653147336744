import { GetStaticProps } from 'next';

import { Head, Image } from 'aresui';
import { useBoolean } from 'codekit';
import { motion } from 'framer-motion';
import { Waypoint } from 'react-waypoint';

import Button from 'components/Button';
import { app } from 'config/app';
import { Icons } from 'icons';
import Institutional from 'layouts/Institutional';
import { useFirstInteraction } from '../../hooks/useFirstInteraction';

import ImageScreenshot from 'assets/home-demo-result-preview.webp';

import { HomeContainer } from './styles';

function Home(): JSX.Element {
	// Hooks
	const userInteracted = useFirstInteraction();

	// Boolean hooks
	const isAnimatedTalkToExpertSection = useBoolean();
	const isPresentingDemo = useBoolean();

	return (
		<HomeContainer>
			<Head
				title={`${app.name} - Automatize a classificação e conversão de balancetes com AI`}
			>
				<meta name="description" content={app.description} />
			</Head>

			<Institutional.Header />

			<Institutional.Section
				id="banner"
				height="auto"
				// paddingVertical="136px"
				paddingVertical="70px"
			>
				<Institutional.Section.Banner />
			</Institutional.Section>

			<Institutional.Section id="screenshot" paddingVertical="60px">
				<motion.div
					className="screenshot"
					initial={{
						y: 30,
						opacity: 0,
					}}
					animate={{
						y: 0,
						opacity: 1,
					}}
					transition={{
						duration: 0.5,
					}}
				>
					<Image
						src={ImageScreenshot}
						alt=""
						width={1100}
						height={630}
						quality={100}
						placeholder="blur"
						priority
						rel="preload"
						style={{
							opacity: !isPresentingDemo.value ? 1 : 0,
						}}
					/>

					{!isPresentingDemo.value && (
						<div className="overlay">
							<p>
								Clique no botão abaixo <br />
								para ver uma demo de como funciona
							</p>

							<Button onClick={isPresentingDemo.setTrue}>
								<Icons.LuEye />
								Ver demo
							</Button>
						</div>
					)}

					{userInteracted && (
						<iframe
							src="https://app.supademo.com/embed/cm3t1hmnu1dma3vuklvgmkm51?embed_v=2"
							loading="lazy"
							title="Balancete AI Demo"
							allow="clipboard-write"
							frameBorder={0}
							allowFullScreen
							style={{
								opacity: isPresentingDemo.value ? 1 : 0,
							}}
						/>
					)}
				</motion.div>
			</Institutional.Section>

			{/* <Institutional.Section id="video">
				<iframe
					width={560 * 1.7}
					height={315 * 1.7}
					src="https://www.youtube-nocookie.com/embed/-uoj2-_AaZ8?si=b3SzWtLinrOjYZLg"
					title="YouTube video player"
					frameBorder={0}
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerPolicy="strict-origin-when-cross-origin"
					allowFullScreen
				/>
			</Institutional.Section> */}

			{/* <Institutional.Section id="customers" paddingVertical="64px">
				<Institutional.Section.Customers />
			</Institutional.Section>

			<Institutional.Section id="testimonials">
				<Institutional.Section.Testimonials />
			</Institutional.Section> */}

			<Waypoint onEnter={isAnimatedTalkToExpertSection.setTrue}>
				<motion.div
					animate={
						!isAnimatedTalkToExpertSection.value
							? {
									y: 30,
									opacity: 0,
									scale: 0.8,
								}
							: {
									y: 0,
									opacity: 1,
									scale: 1,
								}
					}
					transition={{
						delay: 0.3,
						duration: 1,
					}}
				>
					<Institutional.Section id="talk-to-a-expert">
						<Institutional.Section.TalkToAExpert />
					</Institutional.Section>
				</motion.div>
			</Waypoint>

			{/* <Institutional.Section id="steps">
				<Institutional.Section.Steps />
			</Institutional.Section> */}

			<Institutional.Section id="benefits">
				<Institutional.Section.Benefits />
			</Institutional.Section>

			{/* <Institutional.Section id="classification">
				<div className="resource">
					<ClassificationResource />
				</div>

				<div className="text">
					<Institutional.Tag>
						Classificação de balancetes
					</Institutional.Tag>

					<h1>Precisão automatizada & eficiente</h1>

					<p>
						Utilize nossa tecnologia avançada de inteligência
						artificial para automatizar a classificação precisa de
						balancetes, liberando seu tempo e garantindo a
						confiabilidade dos seus dados financeiros.
					</p>

					<Institutional.Button>
						Otimize agora
						<Icons.LuMoveRight className="move-right" />
					</Institutional.Button>
				</div>
			</Institutional.Section>

			<Institutional.Section id="conversion">
				<div className="text">
					<Institutional.Tag>
						Conversão de balancetes
					</Institutional.Tag>

					<h1>Transformação instantânea e simplificada</h1>

					<p>
						Converta rapidamente balancetes em formatos de planilha
						prontos para análise, sem a necessidade de especificar o
						formato ou o período do documento. Automatize seu
						processo de due diligence e acelere suas decisões em
						M&A.
					</p>

					<Institutional.Button>
						Experimente gratuitamente
						<Icons.LuMoveRight className="move-right" />
					</Institutional.Button>
				</div>

				<div className="resource">
					<ConversionResource />
				</div>
			</Institutional.Section> */}

			<Institutional.Section id="contact-us">
				<Institutional.Section.ContactUs />
			</Institutional.Section>

			<Institutional.Footer />
		</HomeContainer>
	);
}

export const getStaticProps: GetStaticProps = () => {
	return {
		props: {
			data: {},
		},
	};
};

export default Home;
